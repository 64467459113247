import { useEffect, useState } from "react";

const CustomCursor = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event) => {
      setPosition({ x: event.clientX, y: event.clientY });
    };
    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        top: position.y,
        left: position.x,
        width: "16px",
        height: "16px",
        backgroundImage: "/images/Icon2.png",
        backgroundSize: "cover",
        pointerEvents: "none", // Prevents the custom cursor from interfering with clicks
        zIndex: 10000, // Ensure the cursor is on top of all content
        transform: "translate(-50%, -50%)", // Center the cursor on the mouse
      }}
    />
  );
};

export default CustomCursor;
