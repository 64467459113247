import Head from "next/head";
import "../styles/globals.css";
import { Footer } from "../UI/layouts/header/Footer";
import { Header } from "../UI/layouts/header/Header";
import { i18n } from "../../next-i18next.config.js";
import { useEffect } from "react";
import { useTranslation } from "next-i18next";
import { WhatsAppWidget } from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css";
import Script from "next/script";
import { appWithTranslation } from "next-i18next";
import "../UI/components/Input/style.scss";
import { hotjar } from "react-hotjar";
import CustomCursor from "../UI/components/CustomeCursor/index.js";

function MyApp({ Component, pageProps }) {
  const { t } = useTranslation();
  useEffect(() => {
    if (typeof window !== "undefined") {
      hotjar.initialize(2953759, 6);
      // Perform localStorage action
      // const lang = localStorage.getItem('lang');
      // let langTemp = lang !== null ? lang : 'en';
      // i18n.changeLanguage(langTemp);
    }
  }, []);
  return (
    <>
      <Head>
        <meta name="robots" content="noindex" />
        <meta name="googlebot" content="noindex" />
        <meta charSet="utf-8" />
        <meta httpEquiv="Content-Language" content="en" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Coimex</title>

        <meta name="pagesource" content="#" />
        <meta name="copyright" content="© Copyright coimex.co" />
        <meta
          name="author"
          content="https://www.linkedin.com/company/coimex-inc"
        />
        <meta itemProp="name" content="Connect. Trust. Trade." />

        <meta itemProp="image" content="https://coimex.co/logo.png" />
        <meta name="robots" content="index, follow" />
        <meta name="revisit-after" content="1 days" />
        <meta name="language" content="English" />
        <meta name="web_author" content="Coimex" />
        <meta name="url" content="https://coimex.co" />

        <meta property="og:title" content="Coimex" />
        <meta property="og:site_name" content="https://coimex.co" />
        <meta property="og:url" content="https://coimex.co" />
        <meta property="og:type" content="article" />
        <meta property="og:image" content="#" />
        <meta property="og:locale" content="en_US" />
        <meta
          property="article:author"
          href="https://www.linkedin.com/company/coimex-inc"
        />
        <meta property="article:publisher" content="#" />
        <meta name="theme-color" content="#606B8B" />
        <meta name="google-site-verification" content="#" />

        <meta property="article:tag" content="Exporters" />
        <meta property="article:tag" content="Importers" />
        <meta property="article:tag" content="Trad" />
        <meta property="article:tag" content="Trading " />
        <meta property="article:tag" content="digital platform" />
        <meta property="article:tag" content="Export" />
        <meta property="article:tag" content="Import" />
        <meta property="article:tag" content="Coimex" />
        <meta property="article:tag" content="Coimex" />
        <meta
          property="article:published_time"
          content="2022-07-14T11:35:11+03:30"
        />
        <meta
          property="article:modified_time"
          content="2022-07-14T11:35:11+03:30"
        />
        <meta property="og:updated_time" content="2022-07-14T11:35:11+03:30" />

        <link rel="canonical" href="https://coimex.co/" />
        <meta property="fb:admins" content="Coimex" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content="#" />
        <meta name="twitter:title" content="Coimex" />

        <meta name="twitter:image" content="https://coimex.co/logo.png" />
        <link
          rel="alternate"
          type="application/rss+xml"
          title="Coimex"
          href="#"
        />

        <link rel="shortcut icon" href="https://coimex.co/logo.png" type="#" />
        <link rel="icon" href="/logo.png" type="https://coimex.co/logo.png" />

        <link rel="author" href="https://www.linkedin.com/company/coimex-inc" />

        <meta name="p:domain_verify" content="#" />
        <meta name="samandehi" content="#" />
      </Head>
      <Header />
      <CustomCursor />
      <Component {...pageProps} />

      <Footer />
      <WhatsAppWidget phoneNumber="+905324479299" />
    </>
  );
}

export default appWithTranslation(MyApp);
