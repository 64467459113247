import * as React from "react";

export default function Flags({ lan, handleLanguage }) {
  return (
    <div
      className="border-2 border-gray-300 rounded-full "
      onClick={handleLanguage}
    >
      {lan === "en" ? <SvgUSFlag /> : <SvgTrFlag />}
    </div>
  );
}

const SvgUSFlag = () => (
  <div className="cursor-pointer">
    <img src="/images/us-flag.svg" alt="usa-flag" width={28} height={28} />
  </div>
);

const SvgTrFlag = () => (
  <div className="cursor-pointer">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 32 32"
    >
      <circle cx="16" cy="16" r="16" fill="#FF0000"></circle>
      <path
        d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
        opacity=".15"
      ></path>
      <path
        d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
        fill="#fff"
        opacity=".2"
      ></path>
      <path
        fill="#fff"
        d="M19.807 16L21 14.358 19.069 14.985 17.876 13.342 17.876 15.373 15.945 16 17.876 16.627 17.876 18.658 19.069 17.015 21 17.642 19.807 16z"
      ></path>
      <path
        d="M15.953,19.325c-1.837,1.65-4.663,1.5-6.314-.337s-1.5-4.663,.337-6.314c1.837-1.65,4.663-1.5,6.314,.337-.442-.699-1.035-1.292-1.734-1.734-2.608-1.65-6.06-.874-7.711,1.734-1.65,2.608-.874,6.06,1.734,7.711,2.608,1.65,6.06,.874,7.711-1.734-.106,.118-.219,.231-.337,.337Z"
        fill="#fff"
      ></path>
    </svg>
  </div>
);
